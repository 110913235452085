import * as React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container'

import './index.scss';
import logo from '../../../assets/images/logo.png';

const Header = () => {
  return (
    <Navbar expand="lg" className="main-nav">
      <Container>
        <Navbar.Brand href="/">
          <img className="logo" src={logo} alt="AAD Gems Booster Club" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link disabled href="/">Donate<br /><small>(coming soon)</small></Nav.Link>
            <Nav.Link disabled href="/">Events<br /><small>(coming soon)</small></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header;
