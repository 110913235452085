import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';

import './index.scss';

const Admin = () => {

  const [authenticated, setAuthenticated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [signupData, setSignupData] = React.useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { formPassword } = event.target;
    if (formPassword.value === process.env.REACT_APP_ADMIN_PASSWORD) {
      setAuthenticated(true);
      setLoading(true);
      const response = await axios.get('/api/signups');
      setSignupData(JSON.parse(JSON.stringify(response.data)));
      setLoading(false);
    }
  };

  return !authenticated ? (
    <Container className="text-center">
      <strong>Please enter admin password:</strong>
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Control required type="password" placeholder="Enter Admin Password" />
        </Form.Group>
        <Button type="submit">Submit</Button>
      </Form>
    </Container>
  ) : (
    <Container>
      <h2 className="py-3">Members</h2>
      <table className="signup-table">
        <thead>
          <tr>
            <th>Dancer</th>
            <th>Parent(s)</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Membership Paid</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <Spinner />
          ) : signupData.map((item, index) => (
            <tr key={`dancer-${index}`}>
              <td>{item.formDancerName}</td>
              <td>{item.formParentName}</td>
              <td>{item.formEmail}</td>
              <td>{item.formPhone}</td>
              <td className={item.paid ? 'text-success' : 'text-danger'}>{item.paid.toString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  )
}

export default Admin;