import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import { usePayPalScriptReducer, PayPalButtons } from '@paypal/react-paypal-js';

import './index.scss';

const Home = () => {
  const [{ isPending }] = usePayPalScriptReducer();
  const formRef = React.useRef(null);
  const [validated, setValidated] = React.useState(false);
  const [approved, setApproved] = React.useState(false);
  const [recordId, setRecordId] = React.useState('');

  const handleChange = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(false);
    } else {
      setValidated(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { formDancerName, formParentName, formEmail, formPhone } = event.target;
    try {
      const response = await axios.post('/api/signups', {
        formDancerName: formDancerName.value, formParentName: formParentName.value, formEmail: formEmail.value, formPhone: formPhone.value, paid: false,
      });
      setRecordId(response.data.insertedId);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    const handlePaid = async () => {
      try {
        await axios.put('/api/signups/paid', {
          id: recordId,
        });
      } catch (err) {
        console.error(err);
      }
    };

    if (recordId && approved) {
      handlePaid();
    }
  }, [recordId, approved])

  return (
    <Container>
      <h1 className="text-center mt-5 mb-5">Welcome to the AAD Gems Booster Club!</h1>
      <div className="text-center"><strong>Here to pay your annual membership fee?</strong></div>
      <div className="text-center">Please fill out the form below and click on the PayPal button to complete the process.</div>
      {approved ? (
        <h2 className="text-center mt-3">Thank you for successfully paying your membership fee!</h2>
      ) : (
        <div className="d-block mx-auto paypal-container">
          <strong>All fields required.</strong>
          <Form noValidate validated={validated} onChange={handleChange} onSubmit={handleSubmit} ref={formRef}>
            <Form.Group className="mb-3" controlId="formDancerName">
              <Form.Label>Full Name of Dancer:</Form.Label>
              <Form.Control required type="text" placeholder="Enter Dancer Name" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formParentName">
              <Form.Label>Full Name of Parent/Guardian:</Form.Label>
              <Form.Control required type="text" placeholder="Enter Parent/Guardian Name" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Parent/Guardian Email:</Form.Label>
              <Form.Control required type="email" placeholder="Enter Parent/Guardian Email" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label>Parent/Guardian Phone:</Form.Label>
              <Form.Control required type="phone" placeholder="Enter Parent/Guardian Phone" />
            </Form.Group>
          </Form>
          {isPending ? <Spinner animation="border" variant="secondary" /> : null}
          <PayPalButtons
            disabled={!validated}
            fundingSource="paypal"
            style={{ "color": "black", "layout": "vertical" }}
            createOrder={(_, actions) => {
              return actions.order
                .create({
                  purchase_units: [{
                    amount: {
                      value: "10",
                      currency_code: "USD",
                      breakdown: {
                        item_total: {
                          currency_code: "USD",
                          value: "10"
                        }
                      },
                    },
                    items: [{
                      name: "AAD Gems Booster Club Membership Fee",
                      quantity: "1",
                      unit_amount: {
                        currency_code: "USD",
                        value: "10",
                      },
                      category: "DONATION",
                    }],
                  }],
                })
            }}
            onClick={() => {
              if (typeof formRef.current.requestSubmit === 'function') {
                formRef.current.requestSubmit();
              } else {
                formRef.current.dispatchEvent(
                  new Event('submit', { cancelable: true })
                );
              }
            }}
            onApprove={(_, actions) => {
              actions.order.capture();
              setApproved(true);
            }}
          />
        </div>
      )}
    </Container>
  )
}

export default Home;
